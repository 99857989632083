import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    options: Object,
  }

  connect() {
    $(this.element).select2(this.fullOptions())

    // Fire a custom event on change, for compatibility with the conditional-form-fields
    // controller, as Stimulus does not detect the normal change event from Select2
    $(this.element).on('change', () => {
      let event = new Event('select2-change')
      this.element.dispatchEvent(event)
    })
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

  fullOptions() {
    let defaultOptions = {
      width: 'resolve'
    }
    return Object.assign(defaultOptions, this.optionsValue)
  }
}
