import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    predefinedTags: Array,
    options: Object,
  }

  connect() {
    $(this.element).select2(this.fullOptions())
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

  fullOptions() {
    let defaultOptions = {
      tags: this.predefinedTagsValue,
      tokenSeparators: [','],
      width: 'resolve'
    }
    return Object.assign(defaultOptions, this.optionsValue)
  }
}
